import { FC } from 'react';

import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share';

interface Props {
  title: string;
  url: string;
  twitterHandle?: string;
  tags: [];
}

const ShareButtons: FC<Props> = ({ title, url, twitterHandle, tags }) => (
  <div className='share-buttons'>
    <FacebookShareButton url={url}>
      <FacebookIcon size={40} round />
    </FacebookShareButton>

    <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
      <TwitterIcon size={40} round />
    </TwitterShareButton>
  </div>
);

export default ShareButtons;
