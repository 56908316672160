import { FC, Fragment } from 'react';

import { graphql } from 'gatsby';

import BlogDescription from 'components/blocks/blog/BlogDescription';
import SEO from 'components/common/Seo';
import BlogLayout from 'components/layout/BlogLayout';

import toPlainText from 'helpers/toPlainText';

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityAgPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
  }
`;

const BlogPostTemplate: FC = ({ data: { post } }: any) => {
  return (
    <BlogLayout>
      {post && (
        <Fragment>
          <SEO
            title={post.title || 'Untitled'}
            description={toPlainText(post._rawExcerpt)}
            image={post.mainImage}
          />
          <BlogDescription {...post} />
        </Fragment>
      )}
    </BlogLayout>
  );
};

export default BlogPostTemplate;
