import { FC } from 'react';

import Footer from 'components/layout/Footer';
import SiteMetaData from 'components/layout/SiteMetaData';
import Header from 'components/layout/Header';

const BlogLayout: FC = ({ children }) => (
  <div className='blog-layout'>
    <SiteMetaData />
    <Header />
    <title>Alpaca Guild</title>

    {children}
    <Footer />
  </div>
);

export default BlogLayout;
