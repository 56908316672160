import { FC } from 'react';

import { format, distanceInWords, differenceInDays } from 'date-fns';

import isEmpty from 'lodash/isEmpty';

import PortableText from 'components/common/PortableText';
import BackButton from 'components/common/BackButton';
import ShareButtons from 'components/common/ShareButtons';
import ImagePlaceholder from 'components/common/ImagePlaceholder';

import imageUrlFor from 'helpers/imageUrlFor';

interface Props {
  _rawBody: any;
  categories: any;
  title: any;
  mainImage: any;
  publishedAt: any;
  authors: any;
}

const BlogDescription: FC<Props> = ({
  _rawBody,
  categories,
  title,
  mainImage,
  publishedAt,
  authors,
}) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <article className='blog-post-container'>
      <div className='blog-post-content'>
        <ShareButtons title={title} url={url} twitterHandle={authors[0]} tags={categories} />

        <div className='blog-post-body'>
          <BackButton />

          <div className='main-content'>
            <div className='main-image'>
              {mainImage?.asset ? (
                <img
                  src={imageUrlFor(mainImage)
                    .width(900)
                    .height(Math.floor((9 / 18) * 900))
                    .fit('crop')
                    .auto('format')
                    .url()}
                  alt={mainImage.alt}
                />
              ) : (
                <ImagePlaceholder />
              )}
            </div>
            <aside className='meta-content'>
              <div className='categories-authors-union'>
                <div className='categories-list-container'>
                  <div className='categories-list-title'>
                    Categories:&nbsp;
                    {isEmpty(categories) && <em> Missing categories</em>}
                  </div>
                  <ul className='categories-list-items'>
                    {categories.map((category: any) => (
                      <li key={category._id} className='categories-list-item'>
                        {category.title}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className='authors-list-container'>
                  <div className='authors-list-title'>
                    Authors:&nbsp;
                    {isEmpty(authors) && <em> Missing name</em>}
                  </div>

                  <ul className='authors-list-items'>
                    {authors.map(({ author, _key }: any) => (
                      <li key={_key} className='authors-list-item'>
                        <div>{author.name}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {publishedAt && (
                <div className='blog-post-date'>
                  {differenceInDays(new Date(publishedAt), new Date()) > 3
                    ? distanceInWords(new Date(publishedAt), new Date())
                    : format(new Date(publishedAt), 'MMMM Do, YYYY')}
                </div>
              )}
            </aside>

            <h3 className='title'>{title}</h3>

            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </div>
      </div>
    </article>
  );
};

export default BlogDescription;
